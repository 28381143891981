import React, { useEffect, useState } from "react";
import Portfolio from "../../../Model/Portfolio";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './DetailPortfolio.css'
import CarouselDetails from "./CarouselDetails";
import img from '../../../photos/consulting/Group 38868.png'
import img1 from '../../../photos/blue.svg'
import img2 from '../../../photos/green.svg'
import prestation from '../../../photos/prestation.svg'
import accomp from '../../../photos/accomp.svg'
import croissance from '../../../photos/croissance.svg'
import TestimonialCarousel from "../TestimonialCarousel";
import default1 from "../../../photos/default.png"
import default2 from "../../../photos/default2.png"
import default3 from "../../../photos/default3.png"
import { ListPortfolioCarousel } from "./ListPortfolioCarousel";
import { Link } from 'react-router-dom';
import MyButton from "../Services/MyButton";


const DetailPortfolio = (props) => {
  const { state } = props.location
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [portfolios, setPortfolios] = useState([]);
  const [pages, setPages] = useState([]);
  const [id, setId] = useState("");
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [avis, setAvis] = useState();

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${URL_Back}/wp-json/reviews/v1/all`);
        const result = await response.json();
        setAvis(result);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const extractElement = (content, selector, attribute = 'textContent') => {
    const container = document.createElement('div');
    container.innerHTML = content;
    const element = container.querySelector(selector);
    return element ? (attribute === 'textContent' ? element.textContent : element.getAttribute(attribute)) : null;
  };

  const extractLink = (content) => extractElement(content, '.hashtag a', 'href');

  const extractSubtitle = (content) => extractElement(content, '.hashtag h5');

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const scrollIntop = (id) => {
    window.scrollTo(0, 0);
    localStorage.setItem('idPortfolio', id);
  }
  const fetchPortfolios = async () => {
    try {
      let allPortfolios = [];
      let page = 1;
      const pageSize = 20;
      let hasMore = true;
      while (hasMore) {
        const response = await fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=${pageSize}&page=${page}`);
        const result = await response.json();

        if (result.length > 0) {
          allPortfolios = [...allPortfolios, ...result];
          page++;
        } else {
          hasMore = false;
        }
      }
      const languageMap = {
        fr: "<p>French</p>\n",
        en: "<p>English</p>\n",
        ar: "<p>العربية</p>\n",
      };
      const filteredPortfolios = allPortfolios.filter(
        element => element.excerpt.rendered === languageMap[currentLanguage]
      );

      setPortfolios(filteredPortfolios);
    } catch (error) {
      console.error("Error fetching portfolios:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    setId(localStorage.getItem('idPortfolio'))
    if (props.location.state != undefined) {
      setId(props.location.state.id)
    }

    localStorage.removeItem('idPortfolio');
    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var portfolioLange = []
          currentLanguage === "fr"
            ? (result.map(element => { element.excerpt.rendered === "<p>French</p>\n" && portfolioLange.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.excerpt.rendered === "<p>English</p>\n" && portfolioLange.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.excerpt.rendered === "<p>العربية</p>\n" && portfolioLange.push(element) }))
          setPortfolios(portfolioLange)
        }
      )

    fetchPortfolios();

  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.categoriesportfolio, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let item = [];
  portfolios.map(element => {
    splitState[1].length === 2 ?
      element._embedded['wp:featuredmedia'] ?
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[3]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[3]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered)) :
      element._embedded['wp:featuredmedia'] ?
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[2]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[2]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered))
  })

  let portfolio = [];
  portfolios.map(element => element._embedded['wp:featuredmedia']
    ? item[0] !== undefined && console.log() && item[0] !== undefined
      ? item[0].id !== portfolio.id && item[0].id <= portfolio.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
      : state !== undefined && element.id !== state.id && element.id <= state.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
    : item[0] !== undefined && console.log() &&
      item[0] !== undefined ? item[0].id !== portfolio.id && item[0].id <= portfolio.id && portfolio.length < 4 &&
    portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered))
      : state !== undefined && element.id !== state.id && element.id <= state.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered))
  )

  portfolios.map(element =>
    element._embedded['wp:featuredmedia']
      ? portfolio.length < 4
      && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
      portfolio.length < 4
      && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.categoriesportfolio, element.defi_content, element.probleme_content, element.resultat_content, element.nos_actions_content, element.image_logo_content, element.image_caroussel_content, element.image_center_content, element.techo_choice_content, element.date, element.excerpt.rendered))
  )

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/getTag.js";
    script.type = "text/javascript";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  function decodeHtmlCharCodes(str) {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  let titre = item[0] !== undefined
    ? item[0].title.replace("&#8217;", "'").replace("&amp;", "&")
    : "...Loading";
  document.title = titre;

  const content = state !== undefined ? state.content : item[0] ? item[0].content : '';
  const images = state !== undefined ? state.image_caroussel_content : item[0] ? item[0].image_caroussel_content : '';
  const duplicatedImages = [...images, ...images];

  function getParagraphByNumber(content, paragraphNumber) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const paragraphs = Array.from(doc.querySelectorAll('p'))
      .filter(p => !p.closest('.hashtag') && !p.closest('figure'));

    if (paragraphs.length < paragraphNumber) {
      console.warn(`Le paragraphe numéro ${paragraphNumber} est introuvable.`);
      return '';
    }

    return paragraphs[paragraphNumber - 1].innerHTML;
  }

  const projectLink = extractLink(content);

  console.log("item", item)
  console.log("state", state)

  return (
    <>
      <Helmet>
        <title>Oxton Digital | Nos réalisations : {titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid detail-portfolio-container" >
        <div className="container">
          <Breadcrumb>
            <p>
              <Link className="retour" to="/">{t('Home_link')}</Link> / <Link className="retour" to={langue === "fr" ? "/fr/nos-references" : langue === "en" ? "/en/portfolio" : "/ar/مَلَفّ"}>{t('Portfolio_subtitle')}</Link> / <span>{state !== undefined ? decodeHtmlCharCodes(state.title) : item[0] !== undefined ? titre : "....Loading"}</span>
            </p>
          </Breadcrumb>
          <Title>
            <div className="detail-titles d-flex">
              <h1>{state !== undefined ? decodeHtmlCharCodes(state.title) : item[0] !== undefined ? titre : "....Loading"}</h1>
              <h5> {extractSubtitle(content)} </h5>
            </div>
            <div className="title-p" dangerouslySetInnerHTML={{
              __html: state && state.content
                ? getParagraphByNumber(state.content, 1)
                : (item && item[0] && item[0].content
                  ? getParagraphByNumber(item[0].content, 1)
                  : '....Loading')
            }} />


          </Title>
          <div className="button-group-details">
            {state?.techo_choice_content && Array.isArray(state.techo_choice_content) && state.techo_choice_content.length > 0 ? (
              state.techo_choice_content.map((label, index) => (
                <button key={index} className="custom-button-details">
                  <img
                    src={label.src}
                    alt={label.name || "technologie"}
                    className=""
                    onClick={() => {
                      window.location.href = `/fr/filter-portfolio/${label.name}`;
                    }}
                  />
                </button>
              ))
            ) : item?.[0]?.techo_choice_content && Array.isArray(item[0].techo_choice_content) && item[0].techo_choice_content.length > 0 ? (
              item[0].techo_choice_content.map((label, index) => (
                <button key={index} className="custom-button-details">
                  <img
                    src={label.src}
                    alt={label.name || "technologie"}
                    className=""
                    onClick={() => {
                      window.location.href = `/fr/filter-portfolio/${label.name}`;
                    }}
                  />
                </button>
              ))
            ) : null}
            {(state?.categoriesportfolio || item?.[0]?.categoriesportfolio) && (
              <>
                {(state?.categoriesportfolio || item?.[0]?.categoriesportfolio).map((categoryId, index) => {
                  let spanContent = null;
                  if (categoryId === 7) {
                    spanContent = "Design UX/UI";
                  } else if (categoryId === 254) {
                    spanContent = "E-commerce";
                  }

                  return spanContent ? (
                    <button
                      key={`category-${index}`}
                      className="custom-button-details"
                      onClick={() => {
                        window.location.href = `/fr/filter-portfolio/${spanContent}`;
                      }}
                    >
                      <span>{spanContent}</span>
                    </button>
                  ) : null;
                })}
              </>
            )}
          </div>
        </div>

        <div className="carousel-detail-container">
          <CarouselDetails items={duplicatedImages} />
        </div>


        {/* <Carousel /> */}


        <div className="container defi-container mt-5">
          <>
            {((state?.defi_content || item?.[0]?.defi_content) && (
              <div className="respect-container">
                <div className="respect-icon">
                  <img src={img} alt="Réactivité" />
                </div>
                <div className="respect-content">
                  <h3>Le défi</h3>
                  <div className="title-p">
                    {state?.defi_content || item?.[0]?.defi_content || ""}
                  </div>
                </div>
              </div>
            ))}

            {(state?.probleme_content || item?.[0]?.probleme_content) && (
              <div className="problems-section">
                <h2>Problèmes</h2>
                <div
                  className="problems-row problem-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      state?.probleme_content ||
                      item?.[0]?.probleme_content ||
                      "",
                  }}
                />
              </div>
            )}
          </>
          <div className="fourth-image">
            {state?.image_center_content || item?.[0]?.image_center_content ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    state?.image_center_content || item?.[0]?.image_center_content || "",
                }}
              />
            ) : (
              <img src={default2} alt="Image par défaut" />
            )}
          </div>
        </div>


        <div className="container mt-5 solution-container">
          <>
            {(state?.resultat_content || item?.[0]?.resultat_content) && (
              <>
                <div className="respect-container">
                  <div className="respect-icon">
                    <img src={img2} alt="Réactivité" />
                  </div>
                  <div className="respect-content">
                    <h3 className="results-title">Résultats</h3>
                  </div>
                </div>
                <div className="actions-section">
                  <div className="actions-row">
                    <div
                      className="actions-row action-text res"
                      dangerouslySetInnerHTML={{
                        __html: state?.resultat_content || item?.[0]?.resultat_content || "",
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </div>


        <div className="container mt-5 solution-container">
          <>
            {(state?.nos_actions_content || item?.[0]?.nos_actions_content) && (
              <>
                <div className="respect-container">
                  <div className="respect-icon">
                    <img src={img1} alt="Réactivité" />
                  </div>
                  <div className="respect-content">
                    <h3 className="solution-title">Nos actions</h3>
                  </div>
                </div>
                <div className="actions-section">
                  <div
                    className="actions-row action-text act"
                    dangerouslySetInnerHTML={{
                      __html: state?.nos_actions_content || item?.[0]?.nos_actions_content || "",
                    }}
                  />
                </div>
              </>
            )}
          </>
          <div className="fifth-image">
            <a href={projectLink} target="_blank" rel="noopener noreferrer" className="image-link">
              {state?.image_logo_content || item?.[0]?.image_logo_content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      state?.image_logo_content || item?.[0]?.image_logo_content || "",
                  }}
                />
              ) : (
                <img src={default3} alt="Image par défaut" />
              )}
              {projectLink && (
                <div className="link-popup">{projectLink}</div>
              )}
            </a>
          </div>


          <div className="container characteristics-container">
            <div className="characteristic">
              <img src={accomp} />
              <div className="ch-nb-title">
                <div className="ch-title">D’ACCOMPAGNEMENT</div>
                <span className="ch-nb">6 mois</span>
              </div>
            </div>
            <div className="characteristic">
              <img src={croissance} />
              <div className="ch-nb-title">
                <div className="ch-title">CROISSANCE</div>
                <span className="ch-nb">670%</span>
              </div>
            </div>
            <div className="characteristic">
              <img src={prestation} />
              <div className="ch-nb-title">
                <div className="ch-title">PRESTATIONS RÉALISÉES</div>
                <span className="ch-nb">6+</span>
              </div>
            </div>
          </div>
          <div className="voix-detail-page">
            <div className="voix">
              <h2 className="">Voix de nos clients</h2>
              <p className="title-p">Découvrez ce que les clients pensent de nous. Promis, ce sont des vrais clients contents,
                allez voir sur Google !</p>
              <TestimonialCarousel testimonials={avis} />
            </div>
          </div>

          <div className="detail-page-portfolio">
            <div className="respect-container">
              <div className="respect-content">
                <h3>Découvrir d’autres études de cas client</h3>
              </div>
            </div>
            <ListPortfolioCarousel portfolio={portfolio} langue={langue} />
          </div>

          <div className="detail-page-meet">
            <div className="respect-container">
              <div className="respect-content">
                <h3>Vous avez un projet en tête ?</h3>
              </div>
            </div>

            <div className="detail-meet-rec">


              <div className="meet-rec-title-btn">
                <p className="title-p">Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs !</p>
                <MyButton />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}





export default withTranslation()(DetailPortfolio)
