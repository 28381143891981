import React, { useEffect, useState } from "react";
import { Breadcrumb, Title, WhatIs } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './Prestation.css'
import MyButton from "./MyButton";
import img from '../../../photos/consulting/Group 38868.png'
import img1 from "../../../photos/prestation/Vector (2).svg"
import dev1 from '../../../photos/prestation/dev.svg'
import respect from "../../../photos/prestation/respect.svg"
import flexibility from "../../../photos/prestation/flexibility.svg"
import acces from "../../../photos/prestation/acces.svg"
import reduction from "../../../photos/prestation/reduction.svg"
import SubContactForm from "../Forms/SubContactForm";
import { Link } from 'react-router-dom';


const Prestation = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const staticTitles = {
    en: "Optimisez vos Projets Web et Mobiles",
    fr: "Optimisez vos Projets Web et Mobiles"
  };
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);
 
 
  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime()); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);
 
  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))


  return (
  <>
    <Helmet>
      <title>{page[0] !== undefined && page[0].metatitle}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container prestation-page" >
    <Breadcrumb>
        <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>Sous-traintance</span>
        </p> 
        </Breadcrumb>
      <Title>
        <h1>{titre}</h1>
        <p className="title-p mb-3">
          Chez Oxton Digital, nous vous offrons la possibilité d'externaliser vos projets web et mobiles en toute confiance, en Tunisie. En vous appuyant sur notre expertise, vous bénéficiez de services de développement personnalisés, conçus pour vous permettre d’atteindre vos objectifs tout en réduisant vos coûts. En nous confiant la sous-traitance de vos projets, vous maximisez vos résultats et profitez d’un rapport qualité-prix inégalé.
        </p>
        <MyButton />
      </Title>
      <div className="qualities-container">
      <div className="respect-container">
        <div className="respect-icon">
          <img src={img} alt="Réactivité" />
        </div>
        <div className="qualities-content">
          <h3>Pourquoi externaliser vos projets IT avec Oxton Digital ?</h3>
        </div>
        </div>
      </div>
      <div className="service-container">
        <div className="service-item">
          <div className="icon">
            <div className="d-flex align-items-center gap-3">
              <img src={reduction} alt="Réduction des coûts" />

              <h3>Réduction des coûts</h3></div>
          </div>
          <p>
            Externalisez certaines tâches et économisez jusqu’à 60 % sur vos coûts de développement.
            Notre main-d'œuvre qualifiée et compétitive vous permet de diminuer vos dépenses sans compromettre
            la qualité de vos livrables.
          </p>
        </div>

        <div className="service-item">
          <div className="d-flex align-items-center gap-3">
            <div className="icon">
              <img src={acces} alt="Accès à des compétences spécialisées" />
            </div>
            <h3>Accès à des compétences spécialisées</h3> </div>
          <p>
            Nos développeurs sont des experts dans des domaines tels que la cybersécurité, l’intelligence
            artificielle, et les technologies full-stack. Que vous ayez besoin d'une compétence
            spécifique ou d’une équipe complète dédiée à votre projet, nous avons la solution.
          </p>
        </div>

        <div className="service-item">
          <div className="d-flex align-items-center gap-3">
            <div className="icon">
              <img src={flexibility} alt="Flexibilité et adaptabilité" />
            </div>
            <h3>Flexibilité et adaptabilité</h3> </div>
          <p>
            Externalisez certaines tâches et économisez jusqu’à 60 % sur vos coûts de développement.
            Notre main-d'œuvre qualifiée et compétitive vous permet de diminuer vos dépenses sans compromettre
            la qualité de vos livrables.
          </p>
        </div>

        <div className="service-item">
          <div className="d-flex align-items-center gap-3">
            <div className="icon">
              <img src={respect} alt="Respect des délais et engagements" />
            </div>
            <h3>Respect des délais et engagements</h3> </div>
          <p>
            Grâce à un suivi régulier et transparent, nos équipes vous accompagnent à chaque étape avec des
            rendez-vous physiques ou téléphoniques, des rapports fréquents et une communication fluide.
            Vous restez maître de vos délais et de la qualité attendue.
          </p>
        </div>
        <div className="service-item last-service-item">
          <div className="d-flex align-items-center gap-3">
            <div className="icon">
              <img src={dev1} alt="Développement full-stack" />
            </div>
            <h3>Développement full-stack</h3></div>
          <p>
            Nous prenons en charge vos projets de A à Z, en assurant toutes les étapes du développement logiciel,
            de la conception à la mise en production. Nos développeurs full-stack maîtrisent les technologies
            les plus récentes pour vous offrir des solutions performantes et évolutives.
          </p>
        </div>
      </div>
      <div className="respect">
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content">
            <h3>Vos avantages avec la sous-traitance Oxton Digital</h3>

            <ul className="respect-list">
              <li><div><span>Optimisation des ressources internes</span> : En externalisant vos tâches IT, vous libérez vos équipes internes et les recentrez sur les projets stratégiques de votre cœur de métier.</div></li>
              <li><div><span>Simplicité administrative</span> : Aucun frais de recrutement, de gestion du personnel ou de formation à prévoir. Nous nous occupons de tout pour vous simplifier la vie.</div></li>
              <li><div><span>Vitesse de commercialisation</span> : Grâce à notre méthodologie agile, nous accélérons le déploiement de vos projets, vous permettant de gagner du temps sur vos échéances de lancement.</div></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rdv-container">
        <img src={img1} className="" alt=""/>
      <div className="d-flex flex-column">
      <h4>Faites confiance à Oxton Digital pour la sous-traitance de vos projets IT</h4>
      <p className="title-p mb-0">Nous sommes bien plus qu’un simple prestataire de services. Oxton Digital devient un véritable partenaire stratégique pour vous accompagner dans la réalisation de vos objectifs digitaux. En externalisant en Tunisie, vous profitez non seulement d'un coût avantageux, mais aussi de la qualité d'un service personnalisé, porté par une équipe d'experts dévoués à la réussite de votre projet.</p>
      </div>
        
      </div>

      <div className="meet-rec-agence">
      <div className="respect-container">
              <div className="respect-content">
              <h3>Vous avez un projet en tête ?</h3>
              </div>
            </div>
          <div className="detail-meet-rec">
            <div className="meet-rec-title-btn">
              <p className="title-p">Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs !</p>
              <MyButton />
            </div>
          </div>
        </div>

    </div>





  </>)
}



export default withTranslation()(Prestation)
