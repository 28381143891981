import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Title, Breadcrumb } from "../../../../css/commun";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../../Variables";
import Pages from "../../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import Popup from "../../../Popup";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import img from '../../../../photos/consulting/Group 38868.png'
import seo from '../../../../photos/seo/seo-local-inter.svg'
import gestion from "../../../../photos/seo/gestion.svg"
import strategy from "../../../../photos/seo/strategy.svg"
import results from "../../../../photos/seo/resultats-mesurables.svg"
import audit from '../../../../photos/seo/audit.svg'
import './SubSeo.css'
import cal from '../../../../photos/seo/appointment 1.png'



const SubSeo = (props) => {
  const title = props.subprops.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const staticTitles = {
    en: "Référencement naturel (SEO)",
    fr: "Référencement naturel (SEO)"
  };
  let handler = props.handler;
  let hasError = props.haserror;
  let isLoading = props.isloading;
  const [pages, setPages] = useState([]);
  const [existError, setExistError] = useState();
  const [buttonPopup, setButtonPopup] = useState(false);
  const initialValues = { yourwebsite: '', youremail: '' };
  const [formState, setFormState] = useState(initialValues)
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const validWebSite = /^(http(s?):\/\/)?(www\.)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,3})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;
  const { formState: { errors } } = useForm();
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);



  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


  const handleFieldChange = (field, e) => {
    !errors.yourwebsite && (errors.yourwebsite = {})
    !errors.youremail && (errors.youremail = {})

    setFormState({
      ...formState,
      [field]: e.target.value,
    })

    if (field === "yourwebsite") {
      if (e.target.value.length < 2) {
        errors.yourwebsite.message = "La longueur doit être de 2 ou plus"
        setExistError(false)
      } else if (!validWebSite.test(e.target.value)) {
        errors.yourwebsite.message = "Veuillez saisir valid URL"
        setExistError(false)
      } else {
        errors.yourwebsite = {}
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        errors.youremail.message = "La longueur doit être de 5 ou plus"
        setExistError(false)
      } else if (!validEmail.test(e.target.value)) {
        errors.youremail.message = "L’adresse e-mail n’est pas valide."
        setExistError(false)
      } else {
        errors.youremail = {}
      }
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    !errors.yourwebsite && (errors.yourwebsite = {})
    !errors.youremail && (errors.youremail = {})
    const email = formState.youremail;

    var verifError = true;

    if (!formState.yourwebsite) {
      errors.yourwebsite.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.yourwebsite.length < 2) {
      errors.yourwebsite.message = "La longueur doit être de 2 ou plus"
      verifError = false
    } else if (!validWebSite.test(formState.yourwebsite)) {
      errors.yourwebsite.message = "Veuillez saisir valid URL"
      verifError = false
    }

    if (!formState.youremail) {
      errors.youremail.message = "Ce champ est Obligatoire"
      verifError = false
    } else if (formState.youremail.length < 5) {
      errors.youremail.message = "La longueur doit être de 5 ou plus"
      verifError = false
    } else if (!validEmail.test(email)) {
      errors.youremail.message = "L’adresse e-mail n’est pas valide."
      verifError = false
    }

    verifError && handler(e, formState);
    verifError && e.target.reset();
    setExistError(verifError);
  }

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);


  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description,element.yoast_head_json.title, element.date, element.excerpt.rendered)))



  return (<>
    <Helmet>
      <title>{page[0] !== undefined && page[0].metatitle}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container seo-page-container" >
      <Breadcrumb>
        <p>

          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Breadcrumb>
      <Title>

        <h1>{titre}</h1>

        <p className="title-p mb-3">Chez Oxton Digital, nous comprenons l’importance du référencement naturel (SEO) pour la croissance de votre business. Notre équipe d’experts SEO est formée aux dernières techniques d’optimisation pour vous aider à augmenter votre trafic, à attirer des clients qualifiés et à améliorer durablement votre positionnement sur les moteurs de recherche.</p>
        <Link className="link" to="/fr/formules-seo">
          <button className="button-formules">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32" fill="none">
              <path d="M10.2015 26.397C11.8839 26.397 13.5032 25.9842 14.9504 25.2103L17.5596 29.7518C18.9294 32.3024 22.6558 32.7808 24.6267 30.6786C26.7067 28.6834 26.2253 24.9097 23.7119 23.5177C23.712 23.5177 19.2187 20.867 19.2187 20.867C20.501 18.3802 20.7682 15.4109 19.845 12.6928C19.6873 12.0534 18.7247 12.1738 18.7295 12.8406C20.8341 18.8003 16.5151 25.2936 10.2014 25.2486C5.33008 25.3503 1.03409 20.9984 1.13525 16.0616C1.04177 9.78748 7.50999 5.22164 13.2949 7.42601C13.9955 7.67595 14.3783 6.61299 13.6811 6.3465C7.17492 3.86675 -0.104575 9.00358 0.00194884 16.0618C-0.111216 21.615 4.7205 26.5121 10.2015 26.397ZM17.4133 23.3691C17.8745 22.9017 18.2805 22.3917 18.6384 21.8535L23.1414 24.5101C25.0218 25.5511 25.3814 28.3744 23.8253 29.8668C22.3518 31.4386 19.5624 31.0806 18.5391 29.1742C18.5391 29.1743 15.921 24.6173 15.921 24.6173C16.45 24.2525 16.9508 23.8377 17.4133 23.3691Z" fill="white" />
              <path d="M12.7268 10.3394C13.3853 10.656 13.8597 9.64746 13.2087 9.31009C10.4566 7.93789 7.15092 8.51503 4.98163 10.7471C0.459859 15.4055 3.74415 23.4778 10.0762 23.429C15.823 23.4941 19.3619 16.5799 15.9559 11.7115C15.5327 11.1044 14.6369 11.7609 15.0527 12.3731C16.773 14.8755 16.4931 18.2733 14.3879 20.4512C12.0101 22.8988 8.14212 22.8982 5.76594 20.4524C1.0157 15.3614 6.51647 7.37911 12.7268 10.3394Z" fill="white" />
              <path d="M9.42275 16.4414C9.66463 16.6352 10.0288 16.608 10.2351 16.3769L15.137 10.8923C15.9036 11.2758 16.9527 11.1413 17.5905 10.6108L20.8227 12.8351C20.4675 13.8539 21.3426 14.952 22.4726 14.9339C23.7366 14.965 24.6202 13.5858 23.9998 12.5472L29.9639 6.4998V8.38848C29.9757 9.10369 31.1038 9.10675 31.1164 8.38845V5.11577C31.1038 4.81547 30.8534 4.58313 30.5401 4.57031H27.0826C26.3268 4.5821 26.3238 5.64893 27.0826 5.66122C27.0826 5.66122 29.2135 5.66122 29.2135 5.66122L23.161 11.7983C22.6412 11.5698 21.9521 11.6372 21.4955 11.9497L18.2738 9.73264C18.8929 8.35056 17.7243 6.72043 16.1338 6.75212C14.3105 6.69902 13.1632 8.82847 14.255 10.1897L9.35494 15.6727C9.14954 15.9023 9.17992 16.2469 9.42275 16.4414ZM22.4726 12.7521C23.2299 12.7648 23.2298 13.8304 22.4725 13.843C21.7153 13.8302 21.7154 12.7647 22.4726 12.7521ZM16.1338 7.84302C17.0498 7.81605 17.6227 8.89045 17.0668 9.5692C16.4487 10.4213 14.9612 9.97666 14.9813 8.93389C14.9813 8.33227 15.4982 7.84302 16.1338 7.84302Z" fill="white" />
              <path d="M30.6091 0H9.72289C7.85522 0 6.33594 1.50133 6.33594 3.34694V5.54473C6.34634 6.27875 7.45779 6.27217 7.46492 5.5447V3.34694C7.46492 2.11662 8.47787 1.11565 9.72289 1.11565H30.6091C31.8544 1.11565 32.8671 2.11662 32.8671 3.34694V20.0816C32.8671 21.3122 31.8544 22.3129 30.6091 22.3129H24.9306C24.6186 22.3129 24.3661 22.5624 24.3661 22.8708C24.3661 23.1791 24.6186 23.4286 24.9306 23.4286H30.6091C32.4768 23.4286 33.9961 21.9272 33.9961 20.0816V3.34694C33.9961 1.50133 32.4768 0 30.6091 0Z" fill="white" />
              <path d="M24.7724 19.4277C24.0151 19.4396 24.0117 20.5581 24.7724 20.5706H31.1252C31.8824 20.5588 31.8859 19.4403 31.1252 19.4277H24.7724Z" fill="white" />
              <path d="M31.1259 17.1426H27.656C26.8968 17.1547 26.895 18.273 27.656 18.2854H31.1259C31.8851 18.2733 31.8869 17.155 31.1259 17.1426Z" fill="white" />
              <path d="M13.3143 3.9993C13.9967 3.98695 13.9989 2.86925 13.3143 2.85645H9.15325C8.47084 2.86879 8.46865 3.98647 9.15328 3.9993H13.3143Z" fill="white" />
            </svg></span>
            <span className="">{t('Our formulas')}</span></button>
        </Link>

      </Title>

      <AutreServices>
        {/* Comment l'équipe SEO peut vous aider */}
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content">
            <h3>Pourquoi choisir Oxton Digital pour votre SEO ?</h3>
            <p className="title-p">Les collaborateurs de chez Oxton Digital mettent leur talent et savoir-faire à votre service afin de concevoir des solutions qui vous ressemblent.</p>
          </div>
        </div>

        <div className="seo-services">
          <div>
            <div className="d-flex seo">
              <div>
                <div className="d-flex align-items-center gap-3 seo-img-title">
                  <img src={results} className="" alt="seo" />
                  <h5>Des résultats mesurables</h5>
                </div>
                <p className="title-p">Nous mettons en place une stratégie SEO sur mesure adaptée à vos objectifs. Vous verrez une augmentation de votre visibilité, un trafic plus qualifié et une amélioration du taux de conversion.</p>
              </div>
              <div>
                <div className="d-flex align-items-center gap-3 seo-img-title">
                  <img src={audit} className="" alt="recouvrement" />
                  <h5>Audit SEO personnalisé</h5>
                </div>
                <p className="title-p">Grâce à un audit complet de votre site, nous analysons les points d’amélioration pour booster vos performances. Nous vérifions tous les aspects techniques, les contenus, et l’expérience utilisateur pour garantir un SEO optimal.</p>
              </div>
            </div>
            <div className="d-flex seo">
              <div>
                <div className="d-flex align-items-center gap-3 seo-img-title">
                  <img src={strategy} className="" alt="audit" />
                  <h5>Stratégies de contenu efficaces</h5>
                </div>
                <p className="title-p">Nos experts en contenu SEO vous aident à rédiger des textes optimisés, pertinents pour votre audience et vos mots-clés, augmentant ainsi vos chances d’être bien classé dans les résultats de recherche.</p>
              </div>
              <div>
                <div className="d-flex align-items-center gap-3 seo-img-title">
                  <img src={gestion} className="" alt="contenu" />
                  <h5>Gestion des pénalités</h5>
                </div>
                <p className="title-p">Vous avez été pénalisé par Google ? Nous identifions la cause et mettons en place une stratégie de récupération pour que votre site retrouve rapidement son classement et sa visibilité.</p>
              </div>
            </div>
            <div className="seo last-seo">
              <div>
                <div className="d-flex align-items-center gap-3 seo-img-title">
                  <img src={seo} className="" alt="promotion" />
                  <h5>SEO local et international</h5>
                </div>
                <p className="title-p">Que vous cherchiez à attirer des clients dans votre région ou à vous développer à l’international, nous adaptons nos stratégies SEO pour vous offrir le meilleur retour sur investissement, où que se trouve votre marché cible.</p>
              </div>

            </div>
          </div>
        </div>

        {/* Autres services */}

        <div className="d-flex autres-services">
          <div className="autre-item">
            <div className="card">
              <div className="card-header">
                <img src={`${process.env.PUBLIC_URL}/images/seo/search-engine-optimisation 1.svg`} alt="Optimisez votre SEO On-Site et Off-Site" />
                <h4>Optimisez votre SEO On-Site et Off-Site</h4>

              </div>
              <p className="card-body">
                Nos services couvrent toutes les facettes du SEO, qu’il s’agisse de l’optimisation technique, de la création de liens (link building), ou de la gestion des réseaux sociaux pour renforcer l’autorité de votre site. Nous nous assurons que chaque aspect est pris en compte pour maximiser vos résultats.
              </p>
            </div>
          </div>
          <div className="autre-item item-margin">

            <div className="card">
              <div className="card-header">
                <img src={`${process.env.PUBLIC_URL}/images/seo/seo 1.svg`} alt="Pourquoi le SEO est essentiel pour votre business ?" />

                <h4>Pourquoi le SEO est essentiel pour votre business ?</h4>

              </div>
              <p className="card-body">
                Un site bien positionné sur les moteurs de recherche attire plus de visiteurs, et ces visiteurs sont souvent plus qualifiés. Cela signifie plus de prospects intéressés, plus de ventes, et un retour sur investissement optimal. Le référencement naturel est un levier incontournable pour toute entreprise souhaitant se démarquer de la concurrence et améliorer sa notoriété.
              </p>
            </div>

          </div>

        </div>

      </AutreServices>

      {/* Nos points forts  */}
      <div className="points-forts-seo">
        <div className="points-forts-title d-flex align-items-center gap-3">
          <h3>Nos points forts en SEO</h3></div>
        <div className="seo-points-layout">
          <div className="seo-point left"><div className="content">Gestion des pénalités Google</div></div>
          <div className="seo-point left"><div className="content">Optimisation technique complète</div></div>

          <div className="seo-icon">
            <img src={`${process.env.PUBLIC_URL}/images/seo/Vector.svg`} alt="SEO Icon" />
          </div>

          <div className="seo-point right r1"><div className="content">Audit SEO personnalisé</div></div>
          <div className="seo-point right r2"><div className="content">Contenus optimisés pour le SEO</div></div>

          <div className="seo-point bottom">Suivi de performance et ajustements réguliers</div>
        </div>
      </div>


      {/* formulaire rapport */}
      <Rapport>
        <div className="respect-container">
          <div className="respect-icon">
            <img src={img} alt="Réactivité" />
          </div>
          <div className="respect-content">
            <h3>Demandez votre Audit SEO Gratuit</h3> </div> </div>
        <form className="form" onSubmit={handleFormSubmit} method="POST">

          <FormControl className="inputLeft">
            <InputLabel
              className={langue === "ar" ? "label-ar" : undefined}
              style={{ color: '#000000', marginTop: "-10px" }}
            >
              {t('Seo.website_URL')}
            </InputLabel>

            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yourwebsite", e)} autoComplete="off" />
            {errors.yourwebsite && (<small className="text-danger">{errors.yourwebsite.message}</small>)}
          </FormControl>
          <FormControl className={langue === "ar" ? "inputRight inputRight-ar" : "inputRight"}>
            <InputLabel
              className={langue === "ar" ? "label-ar" : undefined}
              style={{ color: '#000000', marginTop: "-10px" }}
            >
              {t('Seo.website_URL')}
            </InputLabel>

            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("youremail", e)} autoComplete="off" />
            {errors.youremail && (<small className="text-danger">{errors.youremail.message}</small>)}
          </FormControl>
          <div className="d-flex justify-content-end">
            <button type="submit" className={langue === "ar" ? "btn-seo btn btn-primary button-ar submit" : "btn-seo btn btn-primary submit"} onClick={() => setButtonPopup(true)}>
              <span className="d-flex align-items-center gap-3">
                <span><img src={cal} alt="calendrier" /></span>
                {t('Subscribe')}</span></button>
          </div>
          {existError && !isLoading && (
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} onBlur={() => setButtonPopup(false)}>
              <p>{hasError ? "Réessayez plus tard" : t('Your_report_is_in_progress')}</p>
            </Popup>
          )}


        </form>
      </Rapport>

    </div>
  </>)
}



export const AutreServices = styled.div`
  margin-top: 70px;
  @media(max-width: 991px){
    margin-top: 40px;
  }
    @media(max-width: 575px){
      padding: 0px 20px ;
      margin-top:30px ;
    }
     .item-margin{
    position:relative;
    top: 4rem;
}
  >div{
    
    >.autre-item{
      border: 1px solid #17406F;
      border-radius:6px;
      flex:1;
    
       

        >a{
          color:red;
          :hover{
            color: #000 !important;
            box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
}
        }
     
        >.card{
          border-radius:15px;
          border: 0;
          background-color: transparent;
          padding:30px;
          @media(max-width: 991px){
             padding: 22px;
          }
  
          >.card-header{
            background-color: transparent;
            border: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;
            >hr{
              width:113px;
              color: #20ACE3;
              height: 2px;
            }
            >h4{
             font-family: 'RalewayRegular';
             font-size: 26px !important;
             line-height: 42px !important;
             color: #000;
              @media (max-width: 991px) {
               font-size: 22px !important;
               line-height: 36px !important;
               }
               @media (max-width: 576px) {
               font-size: 20px !important;
               line-height: 30px !important;
               }
            }
          }
          >.card-body{
            padding: 0px;
            margin: 0px;
            font-size: 18px;
            line-height: 32px !important;
            font-weight: 400 !important;
            @media(max-width: 991px){
              font-size: 16px;
              line-height: 27px !important;
            }
            @media(max-width: 576px){
              font-size: 14px;
              line-height: 26px !important;
            }
          }
          
        }
      }
    }
  }
`;

const Rapport = styled.div`
  @media(max-width: 575px){
    padding : 0px 20px ;
  }
  >h2{
    text-align: center;
    color: white;
    padding-bottom: 40px;
  }

  >.form {
     margin-top: 30px ;
     margin-left: 5.5rem;
    >.inputLeft{
      width: 47%;
      margin-right: 3%;
      @media (max-width:743px) {
        width: 100%;
        margin-right: 0;
        padding-bottom: 20px;
      }
     
    }
  
    >.inputRight{
      width: 47%;
      margin-left: 3%;
      @media (max-width: 743px) {
        width: 100%;
        margin-left: 0;
        padding-bottom: 10px;
      }
    }
    >.inputRight-ar{
      margin-left: 0;
      margin-right: 4%;
    }
  
    >.inputLeft .input ,>.inputRight .input{
      border: 0;
      border-bottom: 1px solid #17406F;
      background-color: transparent;
      outline: none;

    }

    .text-danger{
      margin-top: 5px;
      height: 0;
    }
  
    input:-webkit-autofill,
    .my-class:-webkit-autofill {
      transition-delay: 9999999s;
    }
  
    >div >.submit{
      
      background-color: rgb(32, 172, 227);
      color: rgb(255, 255, 255);
      margin-top:26px;
      box-shadow: rgba(32, 172, 227, 0.4) 0px 5px 11px 0px;
      font-size: 18px;
      font-weight: 400;
      padding: 10px 30px !important;
      border-radius: 6px !important;
      border: none;
      @media (max-width: 743px) {
        float: left;
      }
    }
  }
`;
;


export default withTranslation()(SubSeo)