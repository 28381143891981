import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './CarouselDetails.css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';


const PrevIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23" fill="none">
        <path d="M0.795163 12.5741L10.7794 22.5586C11.0644 22.8436 11.4442 23 11.8493 23C12.2548 23 12.6344 22.8433 12.9194 22.5586L13.8259 21.6518C14.1107 21.3673 14.2676 20.9872 14.2676 20.5819C14.2676 20.1769 14.1107 19.784 13.8259 19.4995L8.00124 13.662L26.5064 13.662C27.3408 13.662 28 13.0088 28 12.1743L28 10.8924C28 10.0578 27.3408 9.33881 26.5064 9.33881L7.93516 9.3388L13.8257 3.46876C14.1104 3.18375 14.2673 2.81401 14.2673 2.40875C14.2673 2.00394 14.1104 1.6288 13.8257 1.34402L12.9192 0.440228C12.6342 0.155223 12.2545 -9.28643e-05 11.849 -9.29472e-05C11.444 -9.303e-05 11.0642 0.157244 10.7792 0.44225L0.79494 10.4265C0.509259 10.7124 0.352147 11.094 0.35327 11.4997C0.352372 11.9068 0.509259 12.2887 0.795163 12.5741Z" fill="#17406F" />
    </svg>
);

const NextIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="23" viewBox="0 0 28 23" fill="none">
        <path d="M27.2048 10.4259L17.2206 0.441445C16.9356 0.156439 16.5558 0 16.1507 0C15.7452 0 15.3656 0.156663 15.0806 0.441445L14.1741 1.34816C13.8893 1.63272 13.7324 2.0128 13.7324 2.41806C13.7324 2.82309 13.8893 3.21598 14.1741 3.50054L19.9988 9.338L1.49359 9.338C0.659246 9.338 0 9.99118 0 10.8257L0 12.1076C0 12.9422 0.659246 13.6612 1.49359 13.6612L20.0648 13.6612L14.1743 19.5312C13.8896 19.8162 13.7327 20.186 13.7327 20.5912C13.7327 20.9961 13.8896 21.3712 14.1743 21.656L15.0808 22.5598C15.3658 22.8448 15.7455 23.0001 16.151 23.0001C16.556 23.0001 16.9358 22.8428 17.2208 22.5577L27.2051 12.5735C27.4907 12.2876 27.6479 11.906 27.6467 11.5003C27.6476 11.0932 27.4907 10.7113 27.2048 10.4259Z" fill="#17406F" />
    </svg>
);

const CarouselDetails = ({ items }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // useEffect(() => {
    //     if (!isMobile) {
    //         swiperSettings.navigation.prevEl = '.custom-prev-detail';
    //         swiperSettings.navigation.nextEl = '.custom-next-detail';
    //     }
    // }, [isMobile]);

    const displayedItems = isMobile ? items.slice(0, 3) : items;

    const swiperSettings = {
        modules: [Navigation, Pagination, Autoplay],
        spaceBetween: 30,
        slidesPerView: isMobile ? 1 : 1.5,
        centeredSlides: !isMobile,
        loop: true,
        speed: 1500,
        autoplay: false,
        navigation: false,
        pagination:{
            clickable: true, 
            renderBullet: (index, className) => {
                if (index < 3) {
                    return `<span class="${className}"></span>`;
                }
                return ''; 
            },
        }, 
    };




    return (
        <div className="carousel-container-detail">

            <Swiper {...swiperSettings}>
                {displayedItems.map((item, index) => (
                    <SwiperSlide key={`${item.id}-${index}`}> 
                        <div className="carousel-item-detail">
                            <img
                                src={item.src}
                                alt={`Slide ${item.id}`}
                                className="carousel-image-detail"
                            />
                        </div>
                    </SwiperSlide>
                ))}

{/* 
                {!isMobile && (
                    <>
                        <div className="custom-prev-detail">
                            <PrevIcon />
                        </div>
                        <div className="custom-next-detail">
                            <NextIcon />
                        </div>
                    </>
                )} */}
            </Swiper>
        </div>
    );
};

export default CarouselDetails;
